import React from 'react'
import './Renovation.css'

const Renovation = () => {
  return (
    <div>
        <h2 className="renovation__title">Under Renovation, Coming Soon!</h2>
    </div>
  )
}

export default Renovation